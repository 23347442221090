import { orderItemsType } from 'schemas/customer-data/log-purchase'
import * as yup from 'yup'

export default yup
  .object()
  .shape({
    orderId: yup.string().uuid().required(),
    hasB2BProduct: yup.boolean().required(),
    paymentInfo: yup.string().required(),
    items: orderItemsType,
    fromName: yup.string().nullable(),
    toName: yup.string().nullable(),
  })
  .required('A literal object expected')
