import { logger } from '../lib/logger'

const validate = (data, schema, options) => {
  try {
    schema.validateSync(data, options)
  } catch (error) {
    logger.error('[SchemaValidation]', { error, validationInput: data })
    error.validationError = true
    throw error
  }
}

export default validate
