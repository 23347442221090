import * as yup from 'yup'

import { PRODUCTS } from '../../constants'

const ADDONS = PRODUCTS.ADDONS.reduce(
  (seq, addon) => ({
    ...seq,
    [addon]: yup.boolean(),
  }),
  {},
)

export default yup
  .object()
  .shape({
    countryId: yup.string().required(),
    marketId: yup.string().required(),
    currency: yup.string().required(),
    orderId: yup.string().uuid().required(),
    price: yup.number().min(0).required(),
    productType: yup.string().required(),
    profileId: yup.string().uuid().required(),
    requestId: yup.string().uuid(),
    shortId: yup.string().required(),
    userId: yup.string().uuid().required(),
    customerId: yup.string().uuid(),
    ...ADDONS,
  })
  .required('A literal object expected')
