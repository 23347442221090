import * as yup from 'yup'

export default yup
  .object()
  .shape({
    email: yup.string().email().required(),
    formData: yup.object().required(),
    marketId: yup.string().required(),
  })
  .required('A literal object expected')
