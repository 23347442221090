import * as yup from 'yup'

import { PRODUCTS } from '../../constants'

const ADDONS = PRODUCTS.ADDONS.reduce(
  (seq, addon) => ({
    ...seq,
    [addon]: yup.boolean(),
  }),
  {},
)

export const orderItemsType = yup.array().of(
  yup.object().shape({
    productType: yup.string().required(),
    isGift: yup.string().nullable(),
    requestOccasion: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        internalName: yup.string().nullable(),
      })
      .nullable(),
    profile: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        slug: yup.string().nullable(),
        name: yup.string().nullable(),
      })
      .nullable(),
  }),
)

export default yup.object().shape({
  productId: yup.string().required(),
  price: yup.number().min(0).required(),
  currency: yup.string().required(),
  quantity: yup.number().positive().integer().required(),
  purchaseProperties: yup.object().shape({
    coupon: yup.string().nullable(),
    couponDiscount: yup.number().positive().nullable(),
    couponId: yup.string().uuid().nullable(),
    noAddons: yup.number().positive().integer(),
    items: orderItemsType,
    ...ADDONS,
  }),
})
