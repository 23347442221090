import * as yup from 'yup'

export default yup
  .object()
  .shape({
    customerId: yup.string().uuid().required(),
    fromName: yup.string().nullable(),
    isGift: yup.boolean().required(),
    orderId: yup.string().uuid().required(),
    profileId: yup.string().uuid().required(),
    requestId: yup.string().uuid().required(),
    shortId: yup.string().required(),
    toName: yup.string().required(),
    isBuyer: yup.boolean().required(),
  })
  .required('A literal object expected')
