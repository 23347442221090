/* eslint-disable filenames/match-exported */
import { customerData } from '../schemas'
import validate from '../schemas/validate'
import { logger } from './logger'
import config from './nextConfig'

let instance
class CustomerData {
  static EVENTS = [
    { name: 'add-coupon', schema: customerData.addCoupon },
    { name: 'add-to-cart', schema: customerData.addToCart },
    { name: 'checkout-step-5', schema: customerData.checkoutStep5 },
    {
      name: 'newsletter-subscribe',
      schema: customerData.newsletterSubscribe,
    },
    {
      name: 'potential-profile-subscribe',
      schema: customerData.potentialProfileSubscribe,
    },
    {
      name: 'profile-subscribe',
      schema: customerData.profileSubscribe,
    },
    {
      name: 'business-form',
      schema: customerData.businessForm,
    },
    {
      name: 'video-play',
      schema: customerData.videoPlay,
    },
    {
      name: 'customer-payment-attempt',
      schema: customerData.customerPaymentAttempt,
    },
  ]

  static async init() {
    if (!instance) {
      instance = await new CustomerData()
      return instance
    }
    return instance
  }

  static instance() {
    if (!instance) {
      throw new Error('CustomerData not initialized')
    }
  }

  static getRegisteredEventByEventName(eventName) {
    return CustomerData.EVENTS.find((x) => x.name === eventName)
  }

  constructor() {
    return (async () => {
      this.client = await import('@braze/web-sdk-core')
      const initialized = this.client.initialize(
        config.BRAZE_API_KEY_WEB_CUSTOMER,
        {
          baseUrl: config.BRAZE_API_ENDPOINT,
          enableLogging: process.env.NODE_ENV !== 'production',
        },
      )

      if (!initialized) {
        throw new Error('Failed to initialize CustomerData')
      }

      return this
    })()
  }

  identify(userId, options) {
    this.client.changeUser(userId)

    if (options && options.constructor.name === 'Object') {
      this.modify(options)
    }
  }

  modify(options = {}) {
    const user = this.getUser()

    if (options.email) {
      user.setEmail(options.email)
    }

    if (options.phone) {
      user.setPhoneNumber(options.phone)
    }

    if (options.subscriptionType) {
      user.setEmailNotificationSubscriptionType(options.subscriptionType)
    }

    if (options.country) {
      user.setCountry(options.country)
    }

    if (options.language) {
      user.setLanguage(options.language)
    }
  }

  setAttributes(attributes) {
    const user = this.getUser()

    Object.keys(attributes).forEach((attrKey) => {
      const attr = attributes[attrKey]
      if (!user.setCustomUserAttribute(attrKey, attr)) {
        logger.error(
          `[CustomerData Error] Could not enqueue adding ${attrKey} to user`,
        )
      }
    })
  }

  getUser() {
    return this.client.getUser()
  }

  logEvent(event, eventProperties = {}) {
    const registeredEvent = CustomerData.getRegisteredEventByEventName(event)

    if (!registeredEvent) {
      logger.error(
        '[CustomerData Error] Event is not recognized in CustomerData.EVENTS',
        {
          event,
          allowed: CustomerData.EVENTS.map((events) => events.name),
        },
      )
      return false
    }

    if (registeredEvent.schema) {
      try {
        validate(eventProperties, registeredEvent.schema)
      } catch (error) {
        return false
      }
    }

    if (!this.client.logCustomEvent(event, eventProperties)) {
      logger.error('[CustomerData Error] Could not log event', {
        event,
        eventProperties,
      })
      return false
    }

    return true
  }

  logPurchase(payload) {
    try {
      validate(payload, customerData.logPurchase)
      const { productId, price, currency, quantity, purchaseProperties } =
        payload

      if (
        !this.client.logPurchase(
          productId,
          price,
          currency,
          quantity,
          purchaseProperties,
        )
      ) {
        logger.error('[CustomerData Error] Could not track purchase', {
          customerData: payload,
        })
      }
    } catch (error) {
      if (!error.validationError) {
        logger.error('[CustomerData Error]', error)
      }
    }
  }
}
export default CustomerData
