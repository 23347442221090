import { useEffect, useState } from 'react'
import { useAuth } from 'contexts/AuthContext'
import CustomerData from 'lib/customer-data'
import { logger } from 'lib/logger'

import useEffectOnce from 'hooks/useEffectOnce'

export default function useCustomerData(): null | CustomerData {
  const [customerData, setCustomerData] = useState<CustomerData>(null)
  const { currentUser } = useAuth()

  useEffectOnce(async () => {
    try {
      const instance = await CustomerData.init()
      setCustomerData(instance)
    } catch (error) {
      logger.error('[CustomerData Error]', error)
    }
  })

  useEffect(() => {
    if (currentUser && customerData) {
      customerData.identify(currentUser.id)
    }
  }, [customerData, currentUser])

  return customerData
}
