import * as yup from 'yup'

export default yup
  .object()
  .shape({
    code: yup.string().required(),
    couponId: yup.string().uuid().required(),
    orderId: yup.string().uuid().required(),
  })
  .required('A literal object expected')
