import {
  addCoupon,
  addToCart,
  businessForm,
  checkoutStep5,
  customerPaymentAttempt,
  logPurchase,
  newsletterSubscribe,
  potentialProfileSubscribe,
  profileSubscribe,
  videoPlay,
} from './customer-data'

export const customerData = {
  addCoupon,
  addToCart,
  checkoutStep5,
  logPurchase,
  newsletterSubscribe,
  potentialProfileSubscribe,
  profileSubscribe,
  businessForm,
  videoPlay,
  customerPaymentAttempt,
}

export { customerData as default }
